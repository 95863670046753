function initializeParkMap(id, mobileDefaultZoomLevel, desktopDefaultZoomLevel) {
    //console.log("Initializing map with ID: ", id);

    var fetchMetaData = fetch(`${window.merlinConfig.urlPrefix}/assets/maptiles/${id}/metadata.json`);
    var fetchMarkers = fetch(`${window.merlinConfig.urlPrefix}/umbraco/api/parkmap/getmarkers?id=${id}`);

    Promise.all([fetchMetaData, fetchMarkers]).then(values => {

        var arr = values.map(response => {
            return response.json();
        });
        return Promise.all(arr);
    }).then(([metaData, markers]) => {


        //console.log("metaData: ", metaData);
        //console.log("markers: ", markers);

        var mapExtent = metaData.extent;
        var tileExtent = metaData.extent;
        var mapMinZoom = parseInt(metaData.minzoom);
        var mapMaxZoom = parseInt(metaData.maxzoom);
        var mapMaxResolution = parseInt(metaData.tile_matrix[metaData.tile_matrix.length - 1].pixel_size[0]);
        var mapMinResolution = Math.pow(2, mapMaxZoom) * mapMaxResolution;
        var tileSize = parseInt(metaData.tile_matrix[0].tile_size[0]);
        var tileFormat = metaData.format;


        var crs = L.CRS.Simple;
        crs.transformation = new L.Transformation(1, -tileExtent[0], -1, tileExtent[3]);
        crs.scale = function (zoom) {
            return Math.pow(2, zoom) / mapMinResolution;
        };
        crs.zoom = function (scale) {
            return Math.log(scale * mapMinResolution) / Math.LN2;
        };

        var bounds = [
            crs.unproject(L.point(mapExtent[2], mapExtent[3])),
            crs.unproject(L.point(mapExtent[0], mapExtent[1]))
        ];
        //console.log("Bounds: ", bounds);

        //Making a map and tiles
        var map = new L.Map(`map-${id}`, {
            maxZoom: mapMaxZoom,
            minZoom: mapMinZoom,
            crs: crs,
            attributionControl: false
            //zoomControl: false,
        });

        map.fitBounds(bounds);
        map.setMaxBounds(bounds);

        var tiles = new L.tileLayer(`${window.merlinConfig.urlPrefix}/assets/maptiles/${id}/{z}/{x}/{y}.${tileFormat}`, {
            minZoom: mapMinZoom,
            maxZoom: mapMaxZoom,
            tileSize: L.point(tileSize, tileSize),
            bounds: bounds,
            noWrap: true,
            tms: false,
            detectRetina: false,
        });
        tiles.addTo(map);


        //Add the markers

        markers.forEach(marker => {
            var parkMapIcon = L.divIcon({
                iconSize: [40, 40],	// size of the icon //iconSize: [60, 79],	// size of the icon
                iconAnchor: [20, 53],	// point of the icon which will correspond to marker's location
                popupAnchor: [0, -52],	// point from which the popup should open relative to the iconAnchor
                html: marker.Icon
            });

            var m = L.marker([marker.Position.Lat, marker.Position.Lng], { icon: parkMapIcon });
            m.key = marker.Key;

            var link = "";
            if (Boolean(marker.LinkUrl)) {
                link = `<div class="leaflet-link-wrapper"><a href=${marker.LinkUrl}>${marker.LinkText}<svg class="caret" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 32" width="20" height="10" aria-hidden="true" focusable="false">
                <path d="M12.318,16.003L0.795,4.669c-1.061-1.043-1.061-2.735,0-3.778s2.78-1.043,3.841,0L20,16.003L4.636,31.115c-1.061,1.043-2.78,1.043-3.841,0c-1.061-1.043-1.061-2.735,0-3.778L12.318,16.003z"></path>
              </svg></a></div>`;
            }
            if (Boolean(marker.Headline)) {
                m.bindPopup(`<h3>${marker.Headline}</h3><p>${marker.Text}</p>${link}`);
            }
            m.addTo(map);
        });

        map.zoomControl.setPosition('bottomright');
        

        //Set initial zoom level depending on mobile/desktop
        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
            // true for mobile device
            map.setZoom(mobileDefaultZoomLevel);
        } else {
            // false for not mobile device
            map.setZoom(desktopDefaultZoomLevel);
        }

    });
}


window.addEventListener('DOMContentLoaded', () => {
    var parkMaps = document.querySelectorAll('.parkmap');
    if (parkMaps && parkMaps.length > 0) {
        parkMaps.forEach(el => {
            var mapId = el.dataset.mapId;
            var mobileDefaultZoomLevel = el.dataset.mobiledefaultzoom;
            var desktopDefaultZoomLevel = el.dataset.desktopdefaultzoom;
            initializeParkMap(mapId, mobileDefaultZoomLevel, desktopDefaultZoomLevel);
        });
    }


    var mapOverlay = document.querySelector('.map-overlay-mobile');
    

    if (window.detectTouchscreen()) {
        // true for mobile device
        mapOverlay.hidden = false;
        mapOverlay.addEventListener('click', () => {
            mapOverlay.hidden = true;
        });
    }
});
